<template>
<div>
    <transition name="fade">
    <b-row>
        <b-col>
            <validation-observer
                    #default="{ handleSubmit }"
                    ref="refFormObserver"
                >
            <b-form
                class="form-opd-primary"
                @submit.prevent="handleSubmit(onSubmit)"
                @reset.prevent="resetForm"
                >
            <b-card no-body class="p-2">
                <h4 class="text-center">ข้อควรปฏิบัติในการรับประทานยาแก้อักเสบ (ยาปฏิชีวนะ)</h4>
                <h4 class="mt-2">1. ควรดื่มน้ำตามมากๆ หลังรับประทานยา</h4>
                <h4 class="mt-1">2. ถ้ามีอาการคลื่นไส้ให้หยุดรับประทานยาชั่วคราว และเมื่อเป็นปกติแล้วให้รับประทานยาหลังอาหารทันที</h4>
                <h4 class="mt-1">3. ถ้าสงสัยว่ามีอาการแพ้ยาให้หยุดรับประทานยาทันทีและรีบมาพบแพทย์ อาการของการแพ้ยามี ดังนี้ มีไข้ ครั่นเนื้อ ครั่นตัว และเจ็บคอ</h4>
                <h4 class="mt-1">4. รับประทานยา อย่างสม่ำเสมอ</h4>
                <h4 class="mt-1">5. ไม่ควรใช้ในผู้หญิงมีครรภ์, ให้นมบุตร, มีประวัติโรคตับ, ไต</h4>
                <h4 class="mt-1">6. ควรมาพบแพทย์ตามนัด</h4>
                <h4 class="mt-1">7. ไม่ควรซื้อยารับประทานเอง</h4>
                <h4 class="mt-1">8. ถ้ามีโรคประจำตัวหรือแพ้ยา ควรแจ้งให้แพทย์ทราบ</h4>
                <h4 class="mt-2">ข้าพเจ้าได้รับแจ้งจากแพทย์ถึงผลข้างเคียงของยา และอาการแพ้ยา ดังกล่าวแล้ว และไม่มีประวัติการแพ้ยามาก่อน</h4>
    
                <!--<div class="my-1">
                    <button class="btn btn-outline-secondary btn-sm" @click.prevent="$refs.VueCanvasDrawing2.undo()">
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-counterclockwise"
                    viewBox="0 0 16 16"
                    >
                    <path
                        fill-rule="evenodd"
                        d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"
                    />
                    <path
                        d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"
                    />
                    </svg>
                    Undo
                    </button>
                    <button class="mx-1 btn btn-outline-secondary btn-sm" @click.prevent="$refs.VueCanvasDrawing2.redo()">
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-clockwise"
                    viewBox="0 0 16 16"
                    >
                    <path
                        fill-rule="evenodd"
                        d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                    />
                    <path
                        d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"
                    />
                    </svg>
                    Redo
                    </button>

                    <button class="mr-2 btn btn-outline-secondary btn-sm" @click.prevent="$refs.VueCanvasDrawing2.reset()">
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-file-earmark"
                    viewBox="0 0 16 16"
                    >
                    <path
                        d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"
                    />
                    </svg>
                    Reset
                    </button>
                    (คลิกขวาเพื่อบันทึกลายเซ็น)
                </div>
                <div>
                    อัพโหลดลายเซ็น
                    <b-form-file
                    v-model="Data.imagesFile"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    ></b-form-file>
                        <b-media-aside v-if="Data.featuredImage">
                          <b-img
                            :src="Data.featuredImage"
                            height="110"
                            width="170"
                            class="rounded mr-2 mb-1 mb-md-0"
                          />
                        </b-media-aside>
                    <div class="my-1">Selected file: {{ Data.imagesFile ? Data.imagesFile.name : '' }}</div>
                </div>
                -->
                <div class="border mt-4">
                    <p class="text-dark">(ลงชื่อผู้รับการรักษา)</p>
                    <vue-drawing-canvas ref="VueCanvasDrawing2" 
                    :line-cap="'round'"
                    :line-join="'round'"
                    />
                </div>
                <div class="d-inline-block m-2">
                <b-button
                :disabled="loading"
                variant="transpant"
                class="mr-2 border-primary text-primary float-right"
                type="submit"
                >
                ตกลง
                </b-button>
                </div>
            </b-card>
            </b-form>
            </validation-observer>
        </b-col>
    </b-row>
    </transition>
</div>
</template>
<script>
 import {
  BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,BRow,BCol,BFormSelect,
  BButton
 } from 'bootstrap-vue'
 import {
   BSidebar, BBadge, BForm, BFormGroup, BFormRadioGroup, BInputGroup, BFormInput, BFormRadio, BFormTextarea, BFormCheckbox, BFormInvalidFeedback
 } from 'bootstrap-vue'
 import { ref,onUnmounted,beforeMount  } from '@vue/composition-api'
 import Ripple from 'vue-ripple-directive'
 import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
 import router from '@/router'
 import store from '@/store'
 import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
//validate
 import { ValidationProvider, ValidationObserver } from 'vee-validate'
 import { required, alphaNum, email, password,confirmed } from '@validations'
 import formValidation from '@core/comp-functions/forms/form-validation'
 import patientsStoreModule from '../patientsStoreModule'
 import VueDrawingCanvas from "vue-drawing-canvas";
 export default {
    directives: {
        Ripple,
    },
    props: {
    patientId:{
        required:true
    },
    },
    components:{
        BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,BRow,BCol,
        BButton,BInputGroup,BBadge,
        FeatherIcon,ValidationProvider, ValidationObserver,
        BSidebar, BForm, BFormGroup, BFormRadioGroup, BFormInput, BFormRadio, BFormTextarea, BFormCheckbox, BFormInvalidFeedback,
        BFormSelect,VueDrawingCanvas
    },

    setup(props, { emit }){
        const loading = ref(false);

        const toast = useToast()
        const blankData = {
            id:'',
            sign_image2:'',
            sign_date:new Date(),
        }
        const VueCanvasDrawing2 = ref();

        const Data = ref(JSON.parse(JSON.stringify(blankData)))
        const resetData = () => {
        Data.value = JSON.parse(JSON.stringify(blankData))
        }


        if(props?.patientId){
            Data.value.patient_id  = props.patientId;
        }
        const PATIENTS_STORE_MODULE_NAME = 'app-patients';
        if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, patientsStoreModule)
        onUnmounted(() => {
            if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
        });


        const onSubmit = async() => {
            
            
            Data.value.patient_id  = props.patientId;

            Data.value.sign_image2 = VueCanvasDrawing2.value.save()
  
            loading.value = true
            Data.value.ref = 'viva';
            Data.value.sign_date = new Date().toISOString().slice(0,10);
            Data.value.path = '/var/www/html/uploadfiles/uploads/viva/patients/';
            Data.value.savepath = 'uploads/viva/patients/';
         
                loading.value = false
                store.dispatch('app-patients/addPatientSignImageData', Data.value)
                .then((response) => {
                    emit('update:is-welcome-form-sidebar-active', true)
                    emit('update:is-regulation-form-sidebar-active', false)
                   
                    toast({
                    component: ToastificationContent,
                    props: {
                    title: "Save Success",
                    icon: 'SuccessIcon',
                    variant: 'success',
                    },
                })
                }).catch(()=>{
                    console.log('test','abc');
                 
                    loading.value = false
                toast({
                    component: ToastificationContent,
                    props: {
                    title: "Error Save",
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    },
                })
            
                })
        }
        const {
        refFormObserver,
        getValidationState,
        resetForm,
        } = formValidation(resetData)
        return {
            refFormObserver,
            getValidationState,
            resetForm,
            onSubmit,
            loading,
            VueCanvasDrawing2,
            Data
        }
    }
 }
</script>