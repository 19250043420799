<template>
    <div>
            <validation-observer
                    #default="{ handleSubmit }"
                    ref="refFormObserver"
                >
                <b-form
                    class="form-blue"
                    @submit.prevent="handleSubmit(onSubmit)"
                    @reset.prevent="resetForm"
                >
            <b-row>
           
                <b-col lg="12" class="pt-3 pb-2">
                    <label class="text-dark">ประวัติการรักษาด้านผิวพรรณ</label>
                </b-col>
                
               </b-row>
               <b-row>
                <b-col lg="4">
                        <validation-provider
                            #default="validationContext"
                            name="skin_treatment_place"
                        >
        
                            <b-form-group
                            label="เคยรับการรักษาผิวพรรณที่"
                            label-for="skin_treatment_place"
                            >
                                <b-form-input
                                    id="skin_treatment_place"
                                    v-model="Data.skin_treatment_place"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    
                                    
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider
                            #default="validationContext"
                            name="skin_treatment_how"
                        >
        
                            <b-form-group
                            label="ด้วยวิธี"
                            label-for="skin_treatment_how"
                            >
                                <b-form-input
                                    id="skin_treatment_how"
                                    v-model="Data.skin_treatment_how"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    
                                    
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="4">
                        <validation-provider
                            #default="validationContext"
                            name="skin_treatment"
                        >
        
                            <b-form-group
                            label="ขณะนี้ใช้ผลิตภัณฑ์รักษาสิว"
                            label-for="skin_treatment"
                            >
                                <b-form-input
                                    id="skin_treatment"
                                    v-model="Data.skin_treatment"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    
                                    
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="4">
                        <validation-provider
                            #default="validationContext"
                            name="skin_treatment_blemishes"
                        >
        
                            <b-form-group
                            label="ฝ้า"
                            label-for="skin_treatment_blemishes"
                            >
                                <b-form-input
                                    id="skin_treatment_blemishes"
                                    v-model="Data.skin_treatment_blemishes"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    
                                    
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="4">
                        <validation-provider
                            #default="validationContext"
                            name="skin_treatment_maintain"
                        >
        
                            <b-form-group
                            label="บำรุง"
                            label-for="skin_treatment_maintain"
                            >
                                <b-form-input
                                    id="skin_treatment_maintain"
                                    v-model="Data.skin_treatment_maintain"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    
                                    
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="4">
                        <validation-provider
                            #default="validationContext"
                            name="skin_treatment_sun_protection"
                        >
        
                            <b-form-group
                            label="กันแดด"
                            label-for="skin_treatment_sun_protection"
                            >
                                <b-form-input
                                    id="skin_treatment_sun_protection"
                                    v-model="Data.skin_treatment_sun_protection"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    
                                    
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="4">
                        <validation-provider
                            #default="validationContext"
                            name="skin_treatment_clean"
                        >
        
                            <b-form-group
                            label="ทำความสะอาด"
                            label-for="skin_treatment_clean"
                            >
                                <b-form-input
                                    id="skin_treatment_clean"
                                    v-model="Data.skin_treatment_clean"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    
                                    
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
               </b-row>
               
               <div class="d-flex mt-2">
                 <b-button
                   v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                   variant="primary"
                   class="mr-2"
                   type="submit"
                   :disabled="loading"
                 >
                   ต่อไป
                 </b-button>
               </div>
               </b-form>
               </validation-observer>
            </div>
</template>
<script>
import { BBreadcrumb } from 'bootstrap-vue'
import router from '@/router'
 import {
   BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BButton,BTabs, BTab, BFormSelect, BFormDatepicker
 } from 'bootstrap-vue'
 //upload
 import {
  BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,BRow,BCol
 } from 'bootstrap-vue'

//validation
 import { ValidationProvider, ValidationObserver } from 'vee-validate'
 import { ref,onUnmounted } from '@vue/composition-api'
 import { required, alphaNum, email, password,confirmed } from '@validations'
 import formValidation from '@core/comp-functions/forms/form-validation'
 import Ripple from 'vue-ripple-directive'
 import store from '@/store'

 import userPatientsSelectList from '../patients-form/userPatientsSelectList.js'
 import patientsStoreModule from '../patientsStoreModule'

 //file
 import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
 import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
 import ConsentForm from './ConsentForm.vue'
 import RegulationForm from './RegulationForm.vue'
import { useToast } from 'vue-toastification/composition'
export default {
    components:{
        BBreadcrumb,
        BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BButton,BTabs, BTab,
        BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,BRow,BCol,BFormSelect,BFormDatepicker,
        ValidationProvider,
        ValidationObserver,
        ConsentForm,
        RegulationForm
    },
    directives: {
        Ripple,
    },
    data(){
        return {
        }
    },
    props: {
    isTreamentFormSidebarActive: {
      required: true,
    },
    isConsentFormSidebarActive: {
      required: true,
    },
    isRegulationFormSidebarActive: {
      required: true,
    },
    patientId:{
        required: true,
    },
    patientData:{
        required: true,
    },
    },
    setup(props,{ emit }){
        const loading = ref(false);
        const toast = useToast()
        const PATIENTS_STORE_MODULE_NAME = 'app-patients';
        if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, patientsStoreModule)
        onUnmounted(() => {
            if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
        });

        
        const userStorage = JSON.parse(localStorage.getItem('userData'));


        const disabled_input = true

        const blankData = {
        
           id:'',
           skin_treatment_place:'',
           skin_treatment_how:'',
           skin_treatment:'',
           skin_treatment_blemishes:'',
           skin_treatment_maintain:'',
           skin_treatment_sun_protection:'',
           skin_treatment_clean:'',
          
       }

        const Data = ref(JSON.parse(JSON.stringify(blankData)))
        
        if(props?.patientId){
            Data.value.id  = props.patientId;
            store.dispatch('app-patients/fetchData', {id:props.patientId})
           .then((res) => {
            if(res.data.success){
                Data.value = res.data.data[0];
            }
           })
        }

        const resetData = () => {
           Data.value = JSON.parse(JSON.stringify(blankData))
        }

       const onSubmit = () => {
    
        loading.value = true;
        Data.value.ref = 'viva';
        Data.value.path = '/var/www/html/uploadfiles/uploads/viva/patients/';
        Data.value.savepath = 'uploads/viva/patients/';

        let formData = new FormData();
        for(const [key, value] of Object.entries(Data.value)) {
          formData.append(key, value);
        }
    

            store.dispatch('app-patients/updateData', formData)
           .then((res) => {
               if(res.data.success){
                

                toast({
                   component: ToastificationContent,
                   position: 'top-right',
                   props: {
                   title: `Success`,
                   icon: 'BellIcon',
                   variant: 'success',
                   text: `${res.data.message}`,
                   },
               });
                loading.value = false;
                console.log('res',res)
                props.patientId = res.data.data.insertId;
                //emit('update:patient-id', res.data.data.insertId)
                Data.value.code_member = res.data.code_member;

                emit('update:is-consent-form-sidebar-active', true)
               
                emit('update:is-patient-form-sidebar-active', false)
                emit('update:is-regulation-form-sidebar-active', false)
                emit('update:is-treament-form-sidebar-active', false)

               

            return true
                

                                
               }else{
                loading.value = false;
                toast({
                   component: ToastificationContent,
                   position: 'top-right',
                   props: {
                   title: `Error`,
                   icon: 'BellIcon',
                   variant: 'danger',
                   text: `${res.data.message}`,
                   },
               });
           
               
               }
           })
        
       }
       //uploadimage
       const refInputEl = ref(null);
        const refPreviewEl = ref(null);
        const {inputImageRenderer} = useInputImageRenderer(refInputEl, base64 => { 
                refPreviewEl.value.src = base64
        })

        const {
            refFormObserver,
            getValidationState,
            resetForm,
        } = formValidation(resetData)


        
        return {
            Data,
            resetData,
            refFormObserver,
            getValidationState,
            resetForm,
            disabled_input,
            onSubmit,
            inputImageRenderer,
            refInputEl,
            refPreviewEl,
            loading,
        }

    }
    
}
</script>