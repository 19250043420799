<template>
<div>
    <transition name="fade">
    <b-row>
        <b-col>
            <validation-observer
                    #default="{ handleSubmit }"
                    ref="refFormObserver"
                >
            <b-form
                class="form-opd-primary"
                @submit.prevent="handleSubmit(onSubmit)"
                @reset.prevent="resetForm"
                >
                <b-row>
                    <b-col md="6">
                        <b-card no-body class="p-2 text-dark">
                            <validation-provider
                                    #default="validationContext"
                                    name="Drug_Allergy"
                                >
               
                                    <b-form-group
                                    label="Drug Allergy"
                                    label-for="Drug_Allergy"
                                    >
                                        {{ Data.drug_allergy }}
     
                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>

                                <validation-provider
                                    #default="validationContext"
                                    name="Date of Report"
                                >
               
                                    <b-form-group
                                    label="Date of Report"
                                    label-for="Date_of_Report"
                                    >
                                        {{ new Date(Data.sign_date).toLocaleDateString('th-TH' ,{year: 'numeric',month: 'long',day: 'numeric'}) }}
     
                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                        </b-card>
                    </b-col>
                    <b-col md="6">
                        <b-card no-body class="p-2 text-dark">
                            <validation-provider
                                    #default="validationContext"
                                    name="Name"
                                >
               
                                    <b-form-group
                                    label="Name"
                                    label-for="Name"
                                    >
      
                                        {{ Data.name }}
                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>

                                <validation-provider
                                    #default="validationContext"
                                    name="Patient Number"
                                    rules="disable"
                                >
               
                                    <b-form-group
                                    label="Patient Number"
                                    label-for="code_member"
                                    >
                                        {{ Data.code_member }}
     
                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                        </b-card>
                    </b-col>
                </b-row>
                <b-card no-body class="p-2">
                    <b-col md="12" class="text-center">
                        <h3 class="text-dark">หนังสือแสดงความยินยอมการรักษา</h3>
                    </b-col>
                    <b-col md="6" class="text-right">
                        <h3 class="text-dark">วันที่: {{ new Date(Data.sign_date).toLocaleDateString('th-TH' ,{year: 'numeric',month: 'long',day: 'numeric'}) }}</h3>
                    </b-col>

                    <b-col md="12" class="text-start">
                        <h3 class="text-dark">ข้าพเจ้า นาย/นาง/นางสาว ({{ Data.prefix }}) {{ Data.name }}</h3>
                    </b-col>
                    <b-col md="12" class="text-start">
                        <h3 class="text-dark">บัตรประจำตัวประชาชนเลขที่: {{ Data.NationalIDNumber }}</h3>
                    </b-col>
                    <b-col md="12" class="text-start">
                        <h3 class="text-dark">อยู่บ้านเลขที่: {{ Data.address }}</h3>
                    </b-col>
                </b-card>
            <b-card no-body class="p-2">
                <h4 class="text-center">ในฐานะเป็นผู้ขอรักษา</h4>
                <h4 class="mt-2">1. ข้าพเจ้าได้รับการชี้แจงจากคณะแพทย์/เจ้าหน้าที่คลินิกโดยชัดเจนถึงผลดีและผลเสียต่างๆ อันอาจจะเกิดจากการตรวจรักษาพยาบาล และยินยอมเข้ารับการตรวจรักษา ตลอดจนปฏิบัติตามคำแนะนำของคณะแพทย์/เจ้าหน้าที่ทักประการ</h4>
                <h4 class="mt-1">2. ข้าพเจ้ายินยอมโดยสมัครใจให้คณะแพทย์ และ/หรือ บุคลากรอื่นๆ ในวีว่าคลินิคทำการตรวจรักษาและกระทําการใดๆ ตามหลักวิชาชีพทางการแพทย์ ได้ตลอดระยะเวลาที่ข้าพเจ้ารับการตรวจรักษา โดยจะต้องอธิบายข้อมูลรายละเอียดให้แก่ข้าพเจ้าได้เข้าใจและยินยอมก่อนที่จะดำเนินการ อีกทั้งเปิดโอกาส ให้ข้าพเจ้าซักถามจนเป็นที่พอใจ</h4>
                <h4 class="mt-1">3. ในกรณีที่แพทย์ได้กระทำโดยรอบคอบ ให้การตรวจรักษาเป็นอย่างดีโดยถูกต้องตามหลักวิชาการทางการแพทย์และรักษาไว้ซึ่งจรรยาบรรณในการ ตรวจรักษาอย่างเคร่งครัด ข้าพเจ้ารับรองว่าจะไม่ยกเอาการกระทำของคณะแพทย์และบุคลากรอื่นๆ ตามที่ข้าพเจ้าได้ให้ความยินยอมอนุญาตข้างต้น ขึ้นเป็นเหตุว่ากล่าวเอาผิดแค่แพทย์หรือบุคลากรอื่นๆ รวมทั้งวีว่าคลินิคแต่ประการใด เพื่อเป็นหลักฐานในการนี้ ข้าพเจ้าได้ลงลายมือชื่อต่อพยานเป็นสำคัญ</h4>
             
                <!--<div class="my-1">
                    <button class="btn btn-outline-secondary btn-sm" @click.prevent="$refs.VueCanvasDrawing1.undo()">
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-counterclockwise"
                    viewBox="0 0 16 16"
                    >
                    <path
                        fill-rule="evenodd"
                        d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"
                    />
                    <path
                        d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"
                    />
                    </svg>
                    Undo
                    </button>
                    <button class="mx-1 btn btn-outline-secondary btn-sm" @click.prevent="$refs.VueCanvasDrawing1.redo()">
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-clockwise"
                    viewBox="0 0 16 16"
                    >
                    <path
                        fill-rule="evenodd"
                        d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                    />
                    <path
                        d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"
                    />
                    </svg>
                    Redo
                    </button>

                    <button class="mr-2 btn btn-outline-secondary btn-sm" @click.prevent="$refs.VueCanvasDrawing1.reset()">
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-file-earmark"
                    viewBox="0 0 16 16"
                    >
                    <path
                        d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"
                    />
                    </svg>
                    Reset
                    </button>
                    (คลิกขวาเพื่อบันทึกลายเซ็น)
                </div>
                <div>
                    อัพโหลดลายเซ็น
                    <b-form-file
                    v-model="Data.imagesFile"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    ></b-form-file>
                        <b-media-aside v-if="Data.featuredImage">
                          <b-img
                            :src="Data.featuredImage"
                            height="110"
                            width="170"
                            class="rounded mr-2 mb-1 mb-md-0"
                          />
                        </b-media-aside>
                    <div class="my-1">Selected file: {{ Data.imagesFile ? Data.imagesFile.name : '' }}</div>
                </div>

                <div class="border mt-4">
                    <p class="text-dark">(ลงชื่อผู้รับการรักษา)</p>
                    <vue-drawing-canvas ref="VueCanvasDrawing1" 
                    :line-cap="'round'"
                    :line-join="'round'"
                    />
                </div>-->

                <b-form-checkbox
                id="checkbox-1"
                v-model="status1"
                name="checkbox-1"
                :value="true"
                :unchecked-value="false"
                >
                <div class="text-dark">I accept the terms and use</div>
                </b-form-checkbox>


                <div class="d-inline-block m-2">
                <b-button
                :disabled="!status1"
                variant="transpant"
                class="mr-2 border-primary text-primary float-right"
                type="submit"
                >
                ตกลง
                </b-button>
                </div>
            </b-card>
            </b-form>
            </validation-observer>
        </b-col>
    </b-row>
    </transition>
</div>
</template>
<script>
 import {
  BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,BRow,BCol,BFormSelect,
  BButton
 } from 'bootstrap-vue'
 import {
   BSidebar, BBadge, BForm, BFormGroup, BFormRadioGroup, BInputGroup, BFormInput, BFormRadio, BFormTextarea, BFormCheckbox, BFormInvalidFeedback
 } from 'bootstrap-vue'
 import { ref,onUnmounted,beforeMount  } from '@vue/composition-api'
 import Ripple from 'vue-ripple-directive'
 import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
 import router from '@/router'
 import store from '@/store'
 import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
//validate
 import { ValidationProvider, ValidationObserver } from 'vee-validate'
 import { required, alphaNum, email, password,confirmed } from '@validations'
 import formValidation from '@core/comp-functions/forms/form-validation'
 import patientsStoreModule from '../patientsStoreModule'
 import VueDrawingCanvas from "vue-drawing-canvas";

 export default {
    directives: {
        Ripple,
    },
    props: {
    isConsentFormSidebarActive: {
      required: true,
    },
    isRegulationFormSidebarActive: {
      required: true,
    },
    patientId:{
        required: true,
    },
    patientData:{
        required: true,
    },
    },
    components:{
        BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,BRow,BCol,
        BButton,BInputGroup,BBadge,
        FeatherIcon,ValidationProvider, ValidationObserver,
        BSidebar, BForm, BFormGroup, BFormRadioGroup, BFormInput, BFormRadio, BFormTextarea, BFormCheckbox, BFormInvalidFeedback,
        BFormSelect,VueDrawingCanvas,
    },

    setup(props, { emit }){
        const loading = ref(false);
        const status1 = ref(false);
        const toast = useToast()
        const blankData = {
            id:'',
            sign_image1:'',
            sign_image2:'',
            sign_date:new Date(),
        }

        const VueCanvasDrawing1 = ref();
        
        const Data = ref(JSON.parse(JSON.stringify(blankData)))
        const resetData = () => {
        Data.value = JSON.parse(JSON.stringify(blankData))
        }
        if(props?.patientData){
            Data.value.prefix = props?.prefix;
            Data.value.name = props?.patientData?.first_name?props?.patientData?.first_name +' '+props?.patientData?.last_name:''
            Data.value.drug_allergy = props?.patientData?.drug_allergy
            Data.value.address = props?.patientData?.address
            Data.value.NationalIDNumber = props?.patientData?.NationalIDNumber
            Data.value.code_member = props?.patientData?.code_member
        }

        if(props?.patientId){
            Data.value.patient_id  = props.patientId;
        }
        const PATIENTS_STORE_MODULE_NAME = 'app-patients';
        if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, patientsStoreModule)
        onUnmounted(() => {
            if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
        });


        const onSubmit = async() => {

            
            emit('update:is-consent-form-sidebar-active', false)
            emit('update:is-regulation-form-sidebar-active', true)
            return true
            Data.value.patient_id  = props.patientId;

            Data.value.sign_image1 = VueCanvasDrawing1.value.save()
  
            loading.value = true
            Data.value.ref = 'viva';
            Data.value.sign_date = new Date().toISOString().slice(0,10);
            Data.value.path = '/var/www/html/uploadfiles/uploads/viva/patients/';
            Data.value.savepath = 'uploads/viva/patients/';

            
                loading.value = false
                store.dispatch('app-patients/addPatientSignImageData', Data.value)
                .then((response) => {
                    //consentId.value = response.data.data.insertId;
                    emit('update:consentId', response.data.data.insertId)
                    emit('update:is-consent-form-sidebar-active', false)
                    emit('update:is-regulation-form-sidebar-active', true)
                   
                    toast({
                    component: ToastificationContent,
                    props: {
                    title: "Save Success",
                    icon: 'AlertTriangleIcon',
                    variant: 'success',
                    },
                })
                }).catch(()=>{
                    console.log('test','abc');
                 
                    loading.value = false
                toast({
                    component: ToastificationContent,
                    props: {
                    title: "Error Save",
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    },
                })
            
                })

        
        }
        const {
        refFormObserver,
        getValidationState,
        resetForm,
        } = formValidation(resetData)
        return {
            refFormObserver,
            getValidationState,
            resetForm,
            onSubmit,
            Data,
            loading,
            VueCanvasDrawing1,
            status1
            //consentId

        }
    }
 }
</script>