<template>
    <div class="">
        <div  class="p-5">
            <b-card no-body class="p-5">
                <b-col md="12" class="text-center">
                    <h3 class="text-dark">ข้อมูลของคุณถูกบันทึกเข้าระบบเรียบร้อยแล้ว</h3>
                </b-col>
                <b-col md="12" class="text-center">
                    <h3 class="text-dark">ขอบคุณที่ใช้บริการ</h3>
                </b-col>
            </b-card>
        </div>
    </div>
</template>
<script>
import {
    BCard, BRow, BCol, BCollapse, BSidebar, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
    BInputGroup,BInputGroupPrepend,BInputGroupAppend,VBToggle
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
export default {
    components:{
        BCard, BRow, BCol, BCollapse, BSidebar, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
        BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,BFormGroup,BFormCheckbox,
        FeatherIcon,BInputGroup,BInputGroupPrepend,BInputGroupAppend,
    },
    props: {
        isWelcomeFormSidebarActive: {
        required: true,
        }
    }
}
</script>