<template>
    <div>
        <b-card no-body>
            <b-col md="12">
                <treament-form
                v-if="isTreamentFormSidebarActive"
                :patientId.async="patientId"
                :is-treament-form-sidebar-active.sync="isTreamentFormSidebarActive"
                :is-consent-form-sidebar-active.sync="isConsentFormSidebarActive"
                :is-regulation-form-sidebar-active.sync="isRegulationFormSidebarActive"
                :patientData.async="patientData"
                :consentId.sync="Data"
                />
            </b-col>
            <b-col md="12">
                <consent-form 
                v-if="isConsentFormSidebarActive"
                :patientId.async="patientId"
                :is-consent-form-sidebar-active.sync="isConsentFormSidebarActive"
                :is-regulation-form-sidebar-active.sync="isRegulationFormSidebarActive"
                :patientData.async="patientData"
                :consentId.sync="Data"
               
                />
            </b-col>
            <b-col md="12">
                <regulation-form 
                v-if="isRegulationFormSidebarActive"
                :patientId.async="patientId"
                :is-welcome-form-sidebar-active.sync="isWelcomeFormSidebarActive"
                :is-regulation-form-sidebar-active.sync="isRegulationFormSidebarActive"
                :consentId.sync="Data"
                @refetch-data="fetchDataRegister" 
                
                />
            </b-col>
            <b-col md="12">
                <welcome-form 
                v-if="isWelcomeFormSidebarActive"
                :is-welcome-form-sidebar-active.sync="isWelcomeFormSidebarActive"
                />
            </b-col>
        
            <div class="m-2" v-if="isPatientFormSidebarActive">
                <validation-observer
                    #default="{ handleSubmit }"
                    ref="refFormObserver"
                >
                <b-form
                    class="form-blue"
                    @submit.prevent="handleSubmit(onSubmit)"
                    @reset.prevent="resetForm"
                >
                <b-row>
                    <b-col lg="12">

                        <validation-provider
                            #default="validationContext"
                            name="branch"
                            rules="required"
                        >
        
                            <b-form-group
                            label="สาขา"
                            label-for="branch"
                            >
                                <v-select
                                id="branch"
                                v-model="Data.branch_id"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                label="text"
                                :reduce="(option) => option.value"
                                :options="branch"
                                required
                                />
                                <p class="text-danger">required</p>

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <!--<b-col lg="2">
                        <b-media
                        no-body
                        vertical-align="center"
                        class="flex-column flex-md-row"
                      >
                        <b-media-aside>
                          <div class="d-inline-block upload-img">
                            <b-img
                                ref="refPreviewEl"
                                v-if="Data.avatar"
                                :src="Data.avatar"
                                height="120"
                                width="120"
                                class="rounded-circle "
                            />
                            <b-img
                                ref="refPreviewEl"
                                v-else
                                src="@/assets/images/no-img.png"
                                height="120"
                                width="120"
                                class="rounded-circle "
                            />
                            <b-img
                                class="icon-upload"
                            src="@/assets/images/icons/upload.png"
                           
                             
                            />
                            <b-form-file
                            id="fileInput"
                                class="visuallyhidden"
                                ref="refInputEl"
                                size="sm"
                                v-model="Data.imagesFile"
                                accept=".jpg, .png, .gif"
                                
                                @input="inputImageRenderer"
                                />
                          </div>
                          <b-card-text class="my-50">
                           <small class="text-muted">Required image resolution 312x312, image size 3mb.</small>
                          </b-card-text>
                        </b-media-aside>
                      </b-media>
                    </b-col>-->
                    <b-col lg="12">
                        <b-row>
                            <b-col lg="3">
                                <validation-provider
                                    #default="validationContext"
                                    name="prefix"
                                    rules="required"
                                >
               
                                    <b-form-group
                                    label="คำนำหน้า"
                                    label-for="prefix"
                                    >
                                        <b-form-select
                                            :options="title_options"
                                            id="prefix"
                                            v-model="Data.prefix"
                                            autofocus
                                            :state="getValidationState(validationContext)"
                                            trim
                                            
                                            
                                        />
     
                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col lg="3">
                                
                                <validation-provider
                                    #default="validationContext"
                                    name="NationalIDNumber"
                                    rules="min:0"
                                >
               
                                    <b-form-group
                                    label="เลขที่บัตรประชาชน"
                                    label-for="NationalIDNumber"
                                    >
                                        <b-form-input
                                            id="NationalIDNumber"
                                            v-model="Data.NationalIDNumber"
                                            autofocus
                                            :state="getValidationState(validationContext)"
                                            trim
                                            
                                            
                                        />
     
                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col lg="3">
                                <validation-provider
                                    #default="validationContext"
                                    name="gender"
                                    rules="required"
                                >
               
                                    <b-form-group
                                    label="เพศ"
                                    label-for="gender"
                                    >
                                        <b-form-select
                                            :options="options_gender"
                                            id="gender"
                                            v-model="Data.gender"
                                            autofocus
                                            :state="getValidationState(validationContext)"
                                            trim
                                            
                                            
                                        />
     
                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col lg="3">
                                
                                <validation-provider
                                    #default="validationContext"
                                    name="LINEID"
                                    rules="min:0"
                                >
               
                                    <b-form-group
                                    label="LINE ID"
                                    label-for="LINEID"
                                    >
                                        <b-form-input
                                            id="LINEID"
                                            v-model="Data.line_id"
                                            autofocus
                                            :state="getValidationState(validationContext)"
                                            trim
                                            
                                            
                                        />
     
                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col lg="4">
                                <validation-provider
                                    #default="validationContext"
                                    name="first_name"
                                    rules="required"
                                >
               
                                    <b-form-group
                                    label="ชื่อ"
                                    label-for="first_name"
                                    >
                                        <b-form-input
                                            id="first_name"
                                            v-model="Data.first_name"
                                            autofocus
                                            :state="getValidationState(validationContext)"
                                            trim
                                            
                                            
                                        />
     
                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col lg="4">
                                <validation-provider
                                    #default="validationContext"
                                    name="last_name"
                                    rules="required"
                                >
               
                                    <b-form-group
                                    label="นามสกุล"
                                    label-for="last_name"
                                    >
                                        <b-form-input
                                            id="last_name"
                                            v-model="Data.last_name"
                                            autofocus
                                            :state="getValidationState(validationContext)"
                                            trim
                                            
                                            
                                        />
     
                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col lg="4">
                                <validation-provider
                                    #default="validationContext"
                                    name="nick_name"
                                    rules="min:0"
                                >
               
                                    <b-form-group
                                    label="ชื่อเล่น"
                                    label-for="nick_name"
                                    >
                                        <b-form-input
                                            id="nick_name"
                                            v-model="Data.nick_name"
                                            autofocus
                                            :state="getValidationState(validationContext)"
                                            trim
                                            
                                            
                                        />
     
                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col lg="4">
                                <validation-provider
                                    #default="validationContext"
                                    name="birthdate"
                                    rules="required"
                                >
                
                                    <b-form-group
                                    label="วันเกิด"
                                    label-for="birthdate"
                                    >
                                        <b-form-input
                                        type="date"
                                            id="birthdate"
                                            @input="changeBirthday"
                                            v-model="Data.birthdate"
                                         
                                            locale="th-TH"
                                            autofocus
                                            :state="getValidationState(validationContext)"
                                            trim
                                            
                                            
                                        />

                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col lg="4">
                                <validation-provider
                                    #default="validationContext"
                                    name="age"
                                    rules="required"
                                >
                
                                    <b-form-group
                                    label="อายุ"
                                    label-for="age"
                                    >
                                        <b-form-input
                                            id="age"
                                            type="number"
                                            v-model="Data.age"
                                            autofocus
                                            :state="getValidationState(validationContext)"
                                            trim
                                            
                                            
                                        />

                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col lg="4">
                                <validation-provider
                                    #default="validationContext"
                                    name="address"
                                    rules="min:0"
                                >
                
                                    <b-form-group
                                    label="ที่อยู่"
                                    label-for="address"
                                    >
                                    <b-form-textarea
                                            id="address"
                                            type="text"
                                            v-model="Data.address"
                                            autofocus
                                            :state="getValidationState(validationContext)"
                                            trim>

                                    </b-form-textarea>

                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col lg="4">
                                <validation-provider
                                    #default="validationContext"
                                    name="phone"
                                    rules="required"
                                >
                
                                    <b-form-group
                                    label="เบอร์โทร"
                                    label-for="phone"
                                    >
                                        <b-form-input
                                            id="phone"
                                            type="text"
                                            v-model="Data.phone"
                                            autofocus
                                            :state="getValidationState(validationContext)"
                                            trim
                                            
                                            
                                        />

                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col lg="4">
                                <validation-provider
                                    #default="validationContext"
                                    name="education"
                                    rules="required"
                                >
               
                                    <b-form-group
                                    label="การศึกษา"
                                    label-for="education"
                                    >

                                        <b-form-select
                                            :options="educationData"
                                            id="education"
                                            v-model="Data.education"
                                            autofocus
                                            :state="getValidationState(validationContext)"
                                            trim
                                            
                                            
                                        />
     
                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col lg="4">
                                <validation-provider
                                    #default="validationContext"
                                    name="income"
                                    rules="required"
                                >
               
                                    <b-form-group
                                    label="รายได้"
                                    label-for="income"
                                    >

                                        <b-form-select
                                            :options="incomeData"
                                            id="education"
                                            v-model="Data.income"
                                            autofocus
                                            :state="getValidationState(validationContext)"
                                            trim
                                            
                                            
                                        />
     
                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col lg="4">
                                <validation-provider
                                    #default="validationContext"
                                    name="career"
                                    rules="required"
                                >
               
                                    <b-form-group
                                    label="อาชีพ"
                                    label-for="career"
                                    >
     

                                        <b-form-select
                                            :options="careerData"
                                            id="career"
                                            type="text"
                                            v-model="Data.career"
                                            autofocus
                                            :state="getValidationState(validationContext)"
                                            trim
                                            
                                            
                                        />
            
                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>

                                <validation-provider
                                v-if="Data.career == 'อื่นๆ'"
                                            #default="validationContext"
                                            name="career"
                                            rules="required"
                                        >
                        
                                            <b-form-group
                                            label="อื่นๆ"
                                            label-for="career"
                                            >
                                        <b-form-input
                                            id="career"
                                            v-model="Data.career"
                                            autofocus
                                            :state="getValidationState(validationContext)"
                                            trim
                                        />
                                        <b-form-invalid-feedback>
                                                    {{ validationContext.errors[0] }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                            </b-col>
                            <b-col lg="4">

                                <validation-provider
                                    #default="validationContext"
                                    name="know_from"
                                    rules="required"
                                >
                
                                    <b-form-group
                                    label="คุณรู้จักวีว่าคลินิกจากช่องทางใด"
                                    label-for="know_from"
                                    >

                                        <v-select
                                        id="know_from"
                                        v-model="Data.know_from"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        label="text"
                                        :reduce="(option) => option.value"
                                        :options="know_fromData"
                                        multiple
                                        autofocus
                                        :state="getValidationState(validationContext)"
                                        trim
                                        required
                                        />
                                        <p class="text-danger">required</p>
                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>

                                <validation-provider
                                v-if="Data.know_from == 'อื่นๆ' || Data.know_from.includes('อื่นๆ')"
                                    #default="validationContext"
                                    name="know_from"
                                    rules="required"
                                >
                
                                    <b-form-group
                                    label="อื่นๆ"
                                    label-for="know_from"
                                    >
                                <b-form-input
                                    id="know_from"
                                    v-model="Data.know_from_other"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                />
                                <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>

                            </b-col>
                            <b-col lg="4">

                                <validation-provider
                                    #default="validationContext"
                                    name="interested_services"
                                    rules="required"
                                >
                
                                    <b-form-group
                                    label="คุณสนใจบริการด้านใด"
                                    label-for="interested_services"
                                    >

                                    <v-select
                                        id="interested_services"
                                        v-model="Data.interested_services"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        label="text"
                                        :reduce="(option) => option.value"
                                        :options="interested_servicesData"
                                        multiple
                                        autofocus
                                        :state="getValidationState(validationContext)"
                                        trim
                                        required
                                        />

                                        <p class="text-danger">required</p>
                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>

                                <validation-provider
                                v-if="Data.interested_services == 'อื่นๆ' || Data.interested_services.includes('อื่นๆ')"
                                            #default="validationContext"
                                            name="interested_services"
                                            rules="required"
                                        >
                        
                                            <b-form-group
                                            label="อื่นๆ"
                                            label-for="interested_services"
                                            >
                                        <b-form-input
                                            id="interested_services"
                                            v-model="Data.interested_services_other"
                                            autofocus
                                            :state="getValidationState(validationContext)"
                                            trim
                                        />
                                        <b-form-invalid-feedback>
                                                    {{ validationContext.errors[0] }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                            </b-col>
                            

                          
                        </b-row>
    
                    </b-col>

               </b-row>


               <b-row class="mt-3">
                <b-col lg="3">
                                <validation-provider
                                    #default="validationContext"
                                    name="convenient_day"
                                    rules="required"
                                >
                
                                    <b-form-group
                                    label="วันที่สะดวกมาใช้บริการ"
                                    label-for="convenient_day"
                                    >

                                    <v-select
                                        id="convenient_day"
                                        v-model="Data.convenient_day"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        label="text"
                                        :reduce="(option) => option.value"
                                        :options="convenient_dayData"
                                        multiple
                                        autofocus
                                        :state="getValidationState(validationContext)"
                                        trim
                                        required
                                        />
                                        <p class="text-danger">required</p>

                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                    <b-col lg="3">
                        <validation-provider
                            #default="validationContext"
                            name="convenient_time"
                            rules="required"
                        >
        
                            <b-form-group
                            label="ช่วงเวลาที่สะดวกมาใช้บริการ"
                            label-for="convenient_time"
                            >


                            <v-select
                                        id="convenient_time"
                                        v-model="Data.convenient_time"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        label="text"
                                        :reduce="(option) => option.value"
                                        :options="convenient_timeData"
                                        multiple
                                        autofocus
                                        :state="getValidationState(validationContext)"
                                        trim
                                        required
                                        />

                                        <p class="text-danger">required</p>
                         
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    
                    <b-col lg="3">
                        <validation-provider
                            #default="validationContext"
                            name="medical_condition"
                            rules="min:0"
                        >
        
                            <b-form-group
                            label="โรคประจำตัว/การเจ็บป่วยในอดีต"
                            label-for="medical_condition"
                            >
                                <b-form-input
                                    id="medical_condition"
                                    type="text"
                                    v-model="Data.medical_condition"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    
                                    
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="3">
                        <validation-provider
                            #default="validationContext"
                            name="medical_now"
                            rules="min:0"
                        >
        
                            <b-form-group
                            label="ขณะนี้กำลังรักษาโรค"
                            label-for="medical_now"
                            >
                                <b-form-input
                                    id="medical_now"
                                    type="text"
                                    v-model="Data.medical_now"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    
                                    
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    
                    <b-col lg="3">
                        <validation-provider
                            #default="validationContext"
                            name="medicine_now"
                        >
        
                            <b-form-group
                            label="ขณะนี้กำลังรับประทานยา/วิตามิน"
                            label-for="medicine_now"
                            >
                                <b-form-input
                                    id="medicine_now"
                                    v-model="Data.medicine_now"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    
                                    
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="3">
                        <validation-provider
                            #default="validationContext"
                            name="drug_allergy"
                            rules="required"
                        >
        
                            <b-form-group
                            label="ประวัติการแพ้ยา/ผลข้างเคียงจากยา ระบุ"
                            label-for="drug_allergy"
                            >
                                <b-form-input
                                    id="drug_allergy"
                                    type="text"
                                    v-model="Data.drug_allergy"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    
                                    
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <validation-provider
                            #default="validationContext"
                            name="remark"
                        >
        
                            <b-form-group
                            label="อาการแพ้ยา"
                            label-for="remark"
                            >
                                <b-form-textarea
                                    id="remark"
                                    v-model="Data.remark"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    
                                    
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    
               </b-row>
               <b-row>
                    <b-col lg="6" class="d-none">
                        <validation-provider
                            #default="validationContext"
                            name="tag_id"
                            rules="min:0"
                        >
        
                            <b-form-group
                            label="แทกลูกค้า"
                            label-for="tag_id"
                            >
                                <b-form-select
                                    :options="tags_Data"
                                    id="tag_id"
                                    type="text"
                                    v-model="Data.tag_id"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    
                                    
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6" class="d-none">
                        <validation-provider
                            #default="validationContext"
                            name="age"
                            rules="min:0"
                        >
        
                            <b-form-group
                            label="กลุ่มลูกค้า"
                            label-for="group_id"
                            >
                                <b-form-select
                                    :options="group_Data"
                                    id="group_id"
                                    type="text"
                                    v-model="Data.group_id"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    @input="chageGroup($event)"
                                    
                                    
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                       
                        
                    </b-col>
                    <b-col lg="6" class="d-none">
                        <b-form-group
                            label="วันหมดอายุ"
                            label-for="expire_date"
                            >
                               
                            <b-form-datepicker
                           
                                v-model="Data.expire_date"
                                id="datepicker-valid"
                                :state="true"
                            />
                              
                            </b-form-group>
                    </b-col>
    
               </b-row>

               <b-form-checkbox
                id="checkbox-1"
                v-model="status1"
                name="checkbox-1"
                :value="true"
                :unchecked-value="false"
                >
                <div class="text-dark">I accept the terms and use</div>
                </b-form-checkbox>

                <b-form-checkbox
                id="checkbox-2"
                v-model="status2"
                name="checkbox-2"
                :value="true"
                :unchecked-value="false"
                >
                <div class="text-dark">ยินยอมเปิดเผยข้อมูล....</div>
                </b-form-checkbox>
                <div class="d-flex mt-2">
                 <b-button
                   v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                   variant="primary"
                   class="mr-2"
                   type="submit"
                   :disabled="onAccept()"
                 >
                   ต่อไป

                 </b-button>
               </div>
                </b-form>
                </validation-observer>
               
            </div>
           
        </b-card>
    </div>
    </template>
    <script>
    import { BBreadcrumb } from 'bootstrap-vue'
    import router from '@/router'
     import {
       BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BButton,BTabs, BTab, BFormSelect, BFormDatepicker
     } from 'bootstrap-vue'
     //upload
     import {
      BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,BRow,BCol
     } from 'bootstrap-vue'
    
    //validation
     import { ValidationProvider, ValidationObserver } from 'vee-validate'
     import { ref,onUnmounted } from '@vue/composition-api'
     import { required, alphaNum, email, password,confirmed } from '@validations'
     import formValidation from '@core/comp-functions/forms/form-validation'
     import Ripple from 'vue-ripple-directive'
     import store from '@/store'
 
     import userPatientsSelectList from '../patients-form/userPatientsSelectList.js'
     import patientsStoreModule from '../patientsStoreModule'

     //file
     import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
     import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
     import ConsentForm from './ConsentForm.vue'
     import RegulationForm from './RegulationForm.vue'
     import TreamentForm from './TreamentForm.vue'
     import WelcomeForm from './WelcomeForm.vue'
    import { useToast } from 'vue-toastification/composition'
    import vSelect from 'vue-select'
    export default {
        components:{
            BBreadcrumb,vSelect,
            BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BButton,BTabs, BTab,
            BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,BRow,BCol,BFormSelect,BFormDatepicker,
            ValidationProvider,
            ValidationObserver,
            ConsentForm,
            RegulationForm,
            TreamentForm,
            WelcomeForm
        },
        directives: {
            Ripple,
        },
        data(){
            return {
            }
        },
        setup(props,{ emit }){
            const loading = ref(false);
            const status1 = ref(false);
            const status2 = ref(false);
            const toast = useToast()
            const PATIENTS_STORE_MODULE_NAME = 'app-patients';
            if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, patientsStoreModule)
            onUnmounted(() => {
                if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
            });
            const {
                fetchtag_patient,
                tags_Total,
                tags_Data,
            } = userPatientsSelectList();

            const {
                fetchgroup_patient,
                group_Total,
                group_Data,
            } = userPatientsSelectList();

            
            const userStorage = JSON.parse(localStorage.getItem('userData'));
            const isConsentFormSidebarActive = ref(false);
            const isRegulationFormSidebarActive = ref(false);
            const isPatientFormSidebarActive = ref(true);
            const isTreamentFormSidebarActive = ref(false);
            const isWelcomeFormSidebarActive = ref(false);
            const patientId = ref(0);
            const consentId = ref(0);
            const patientData = ref({});

            const title_options = ref([
            { value: 'คุณ', text: 'คุณ' },
            { value: 'นาย', text: 'นาย' },
            { value: 'นาง', text: 'นาง' },
            { value: 'นางสาว', text: 'นางสาว' },
            ]);

            const blood_options = ref([
            { value: 'A+', text: 'A+' },
            { value: 'A-', text: 'A-' },
            { value: 'B+', text: 'B+' },
            { value: 'B-', text: 'B-' },
            { value: 'AB+', text: 'AB+'},
            { value: 'AB-', text: 'AB-'},
            { value: 'O+', text: 'O+'},
            { value: 'O-', text: 'O-'},
            ]);
            const options_gender = ref([
                { value: null, text: 'Please select an option' },
                { value: 'male', text: 'ชาย' },
                { value: 'female', text: 'หญิง' },
                { value: 'lgbq', text: 'LGBQ' },
            ]);

            const know_fromData = ref([
                { value: 'เดินผ่าน / ป้ายหน้าร้าน', text: 'เดินผ่าน / ป้ายหน้าร้าน' },
                { value: 'เพื่อนแนะนำ', text: 'เพื่อนแนะนำ' },
                { value: 'เฟซบุ๊ก', text: 'เฟซบุ๊ก' },
                { value: 'เว็บไซต์', text: 'เว็บไซต์' },
                { value: 'IG', text: 'IG' },
                { value: 'อื่นๆ', text: 'อื่นๆ' },
            ]);

            const educationData = ref([
                { value: null, text: 'Please select an option' },
                { value: 'ประถมศึกษา หรือ น้อยกว่า', text: 'ประถมศึกษา หรือ น้อยกว่า' },
                { value: 'มัธยมต้น', text: 'มัธยมต้น' },
                { value: 'ปวช. / มัธยมปลาย', text: 'ปวช. / มัธยมปลาย' },
                { value: 'ปวส. / อนุปริญญา', text: 'ปวส. / อนุปริญญา' },
                { value: 'ปริญญาตรี', text: 'ปริญญาตรี' },
                { value: 'ปริญญาโท หรือ สูงกว่า', text: 'ปริญญาโท หรือ สูงกว่า' },
            ])

            const careerData =ref([
            { value: null, text: 'Please select an option' },
            { value: 'นักเรียน / นักศึกษา', text: 'นักเรียน / นักศึกษา' },
            { value: 'ข้าราชการ / พนักงานรัฐวิสาหกิจ', text: 'ข้าราชการ / พนักงานรัฐวิสาหกิจ' },
            { value: 'ค้าขาย / ธุรกิจส่วนตัว', text: 'ค้าขาย / ธุรกิจส่วนตัว' },
            { value: 'อาชีพอิสระ', text: 'อาชีพอิสระ' },
            { value: 'แพทย์ / พยาบาล', text: 'แพทย์ / พยาบาล' },
            { value: 'อื่นๆ', text: 'อื่นๆ' },
            ])



            const incomeData = ref([
            { value: null, text: 'Please select an option' },
                { value: 'น้อยกว่า 25,000 บาท', text: 'น้อยกว่า 25,000 บาท' },
                { value: '25,001-50,000 บาท', text: '25,001-50,000 บาท' },
                { value: '50,001-100,000 บาท', text: '50,001-100,000 บาท' },
                { value: 'มากกว่า 100,000 บาท', text: 'มากกว่า 100,000 บาท' },
            ])

            const interested_servicesData = ref([
                { value: 'สิว', text: 'สิว' },
                { value: 'ฝ้า กระ จุดด่างดำ', text: 'ฝ้า กระ จุดด่างดำ' },
                { value: 'แผลเป็น', text: 'แผลเป็น' },
                { value: 'ริ้วรอย', text: 'ริ้วรอย' },
                { value: 'ดูแลผิวพรรณ', text: 'ดูแลผิวพรรณ' },
                { value: 'ลดน้ำหนัก กระชับสัดส่วน', text: 'ลดน้ำหนัก กระชับสัดส่วน' },
                { value: 'กำจัดขน', text: 'กำจัดขน' },
                { value: 'ปรับรูปหน้า', text: 'ปรับรูปหน้า' },
                { value: 'อื่นๆ', text: 'อื่นๆ' },
            ])

            const convenient_dayData = ref([
                { value: 'วันธรรมดา', text: 'วันธรรมดา' },
                { value: 'วันเสาร์', text: 'วันเสาร์' },
                { value: 'วันอาทิตย์ และ/หรือ วันหยุดนักขัตฤกษ์', text: 'วันอาทิตย์ และ/หรือ วันหยุดนักขัตฤกษ์' },
            ])
            
            const convenient_timeData = ref([
                { value: '11.00-12.00น.', text: '11.00-12.00น.' },
                { value: '12.01-14.00น.', text: '12.01-14.00น.' },
                { value: '14.01-16.00น.', text: '14.01-16.00น.' },
                { value: '16.01-18.00น.', text: '16.01-18.00น.' },
                { value: '18.01-20.00น.', text: '18.01-20.00น.' },
            ])

            const branch = ref([]);
            
            store.dispatch('app-patients/fetchBranchData').then((response) => {
                response.data.data.forEach((item)=>{
                    branch.value.push({ value: item.id, text: item.title }); 
                })
            })

            const disabled_input = true

            const blankData = {
            
               id:'',
               icon:'',
               created_by:0,
               created_at:null,
               title: '',
               NationalIDNumber:'',
               gender:'',
               first_name:null,
               last_name:null,
               nick_name:'',
               pagesFile:null,
               branch_id:'',
               remove_image:'',
               ref:'viva',
               expire_date:null,
                income:'',
               career:'',
               education:'',
               know_from:'',
               interested_services:'',
               convenient_day:'',
               convenient_time:'',
               skin_treatment_place:'',
               skin_treatment_how:'',
               skin_treatment:'',
               skin_treatment_blemishes:'',
               skin_treatment_maintain:'',
               skin_treatment_sun_protection:'',
               skin_treatment_clean:'',
               images:require('@/assets/images/no-image.png'),
               imagesFile:null,
               know_from_other:'',
               interested_services_other:''
               
              
           }

            const Data = ref(JSON.parse(JSON.stringify(blankData)))
            
            const resetData = () => {
               Data.value = JSON.parse(JSON.stringify(blankData))
            }
            const changeBirthday = ()=>{
                let currentDate = new Date();
          let birthDate = new Date(Data.value.birthdate);
          let difference = currentDate - birthDate;
          Data.value.age = Math.floor(difference/31557600000);

            }

           const onSubmit = () => {
            //uploadimage
            //if(refPreviewEl.value.src){
            //    Data.value.files = refPreviewEl.value.src;
            //}
            loading.value = true;
            Data.value.ref = 'viva';
            Data.value.birthdate  =  Data.value.birthdate ? Data.value.birthdate :''
            Data.value.tag_id  =  Data.value.tag_id ? Data.value.tag_id :''
            Data.value.path = '/var/www/html/uploadfiles/uploads/viva/patients/';
            Data.value.savepath = 'uploads/viva/patients/';
            if(Array.isArray(Data.value.know_from)){
                Data.value.know_from = Data.value.know_from.join();
            }
            Data.value.know_from = Data.value.know_from +','+Data.value.know_from_other
            if(Array.isArray(Data.value.interested_services)){
                Data.value.interested_services = Data.value.interested_services.join();
            }
            if(Array.isArray(Data.value.convenient_day)){
                Data.value.convenient_day = Data.value.convenient_day.join();
            }
            if(Array.isArray(Data.value.convenient_time)){
                Data.value.convenient_time = Data.value.convenient_time.join();
            }

            Data.value.interested_services = Data.value.interested_services +','+Data.value.interested_services_other


            let formData = new FormData();
            for(const [key, value] of Object.entries(Data.value)) {
              formData.append(key, value);
            }
            
            if(Data.value.tag_patients){
                const tag_patients = Data.value.tag_patients

                formData.append('tag_id', tag_patients);
            }
            if(Data.value.group_patients){
                const group_patients = Data.value.group_patients

                formData.append('group_id', group_patients);
            }


            

                store.dispatch('app-patients/addData', formData)
               .then((res) => {
                   if(res.data.success){
                    
    
                    toast({
                       component: ToastificationContent,
                       position: 'top-right',
                       props: {
                       title: `Success`,
                       icon: 'BellIcon',
                       variant: 'success',
                       text: `${res.data.message}`,
                       },
                   });
                    loading.value = false;
                    console.log('res',res)
                    patientId.value = res.data.data.insertId;
                    //emit('update:patient-id', res.data.data.insertId)
                    Data.value.code_member = res.data.code_member;
                    patientData.value = Data.value;

                    //emit('update:is-patient-form-sidebar-active', false)
                    //emit('update:is-consent-form-sidebar-active', true)
                    //emit('update:is-regulation-form-sidebar-active', false)
                    isPatientFormSidebarActive.value = false;
                    isTreamentFormSidebarActive.value = true;

                    

                                    
                   }else{
                    loading.value = false;
                    toast({
                       component: ToastificationContent,
                       position: 'top-right',
                       props: {
                       title: `Error`,
                       icon: 'BellIcon',
                       variant: 'danger',
                       text: `${res.data.message}`,
                       },
                   });
               
                   
                   }
               })
            
           }
           //uploadimage
           const refInputEl = ref(null);
            const refPreviewEl = ref(null);
            const {inputImageRenderer} = useInputImageRenderer(refInputEl, base64 => { 
                    refPreviewEl.value.src = base64
            })
            const fetchDataRegister = ()=>{
                console.log('test-fetch','abc');
                isConsentFormSidebarActive.value = false;
                isRegulationFormSidebarActive.value = false;
                isPatientFormSidebarActive.value = true;

             
                
            }
            const {
                refFormObserver,
                getValidationState,
                resetForm,
            } = formValidation(resetData)

            const chageGroup = async (event)=>{
                
                const eventIndex = group_Data.value.findIndex(e => e.id === event)
             
                if(eventIndex > -1){
                    const group = group_Data.value[eventIndex];

                    if(group.expire_day > 0){
                    var now = new Date();
                    var duedate = new Date(now);
                    duedate.setDate(now.getDate() + group.expire_day);
                    Data.value.expire_date = duedate.toISOString().slice(0,10);
                }else{

                    Data.value.expire_date = ''

                }
                
                }
                
               
                

            }
            
            return {
                fetchtag_patient,
                fetchDataRegister,
                tags_Total,
                tags_Data,
                fetchgroup_patient,
                group_Total,
                group_Data,
                Data,
                resetData,
                refFormObserver,
                getValidationState,
                resetForm,
                title_options,
                blood_options,
                options_gender,
                disabled_input,
                onSubmit,
                inputImageRenderer,
                refInputEl,
                refPreviewEl,
                chageGroup,
                branch,
                loading,
                isConsentFormSidebarActive,
                isPatientFormSidebarActive,
                patientId,
                patientData,
                isRegulationFormSidebarActive,
                consentId,
                know_fromData,
                educationData,
                interested_servicesData,
                convenient_dayData,
                convenient_timeData,
                incomeData,
                careerData,
                isTreamentFormSidebarActive,
                isWelcomeFormSidebarActive,
                status1,
                status2,
                changeBirthday
            }
    
        },
        methods:{
            onAccept(){
                if(this.status1 == true && this.status2 == true){

                    return false
                }
                return true
            },

        }
        
    }
    </script>